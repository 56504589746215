import React, {Component} from "react"
import styled from 'styled-components'
import {graphql, StaticQuery} from 'gatsby'
import Layout from "components/Layout/Layout"
import TopBar from "../components/shared/TopBar";
import {media} from "utils/Media"
import snowFlakesXs from "../images/snowflakes-mobile.svg"
import snowFlakesXl from "../images/snowflakes-desktop.svg"
import MePlusThree from "../components/MePlusThreePage/MePlusThree";

const PageWrapper = styled.div`
    .enhance {
        text-align: center;

        @media ${media.lg} {
            text-align: left;
        }
    }
    .buttonWrapper {
        justify-content: center;
        margin-bottom: 0;

        @media ${media.lg} {
            justify-content: left;
        }
    }

    .fix-height {

        @media (min-width: 1200px) {
            min-height: 350px;
        }

        @media (min-width: 1400px) {
            min-height: 380px;
        }
        @media (min-width: 1500px) {
            min-height: 400px;
        }
    }

    h2 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`
const SnowFlakes = styled.div`
    background: url("${snowFlakesXs}") repeat 50% 120%;
    background-size: 240%;

    @media ${media.sm} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 290%;
    }

    @media ${media.md} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 190%;
    }

    @media ${media.lg} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 190%;
    }

    @media ${media.xl} {
        background: url("${snowFlakesXl}") no-repeat 50% 70%;
        background-size: 180%;
    }
    .video {
        max-width:600px;
        margin: 0 auto;
    }
`

const MePlusThreeQuery = () => (
    <StaticQuery
        query={graphql`
            query {
                FallbackXs: file(relativePath: { eq: "HomePage/header-vid-ph-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }

                FallbackSm: file(relativePath: { eq: "HomePage/header-vid-ph-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }

                FallbackXl: file(relativePath: { eq: "HomePage/header-vid-ph-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                CloseApp: file(relativePath: { eq: "HomePage/close-app-phone.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 551, quality: 100)
                    }
                }

                videoPlaceholder2: file(relativePath: { eq: "relaxed-perf-580x387.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <MePlusThreePage data={data}/>
        )}
    />
)

class MePlusThreePage extends Component {
    render() {

        return (
            <Layout pageName="four-or-more" title="Four or More" slug="four-or-more">
                <PageWrapper>
                    <TopBar/>
                    <SnowFlakes>
                        <MePlusThree />
                    </SnowFlakes>
                </PageWrapper>
            </Layout>
        )
    }
}

export default MePlusThreeQuery
