import {Link} from "gatsby";
import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {media} from "utils/Media";
import {Col, Container, Row} from "reactstrap";
import {GatsbyImage} from "gatsby-plugin-image";

const EarlyBookerStyles = styled.section`
  width: 100%;

  h2 {
    @media ${media.md} {
      padding: 1.5rem 0 1rem 0;
    }
  }

  ul {
    list-style: none;
    margin: 1rem 0;
    padding: 0;
    font-size: 1rem;
  }
  .listWithPoints {
    list-style: disc;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .terms {
    max-width: 850px;
    margin: 0 auto;
    ul {
      list-style: disc inside;

    }
    p, li, strong {
      font-size: 0.85rem;
      line-height: 1.65;
    }
  }
  strong {
    font-size: 1.2rem;
  }

  section {
    margin: 0 auto;
    padding: 2rem 0;

    .wrapper {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1200px;
      margin: 0 auto;
      align-items: stretch;

      .smaller {
        font-size: 0.85rem !important;
        max-width: 950px;
      }

      .legal {
        margin: 0 auto;
        text-align: center;
      }

      .underline {
        text-decoration: underline;
        cursor: pointer;
      }

      @media ${media.md} {
        width: 85%;
      }
    }
  }

  .title {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;

    h3 {
      margin: 1.5rem 0 0.5rem 0;
    }
  }

  .single_box {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    max-width: 1100px;
  }
`;
const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
  align-items: stretch;
  text-align: center;
`;
const Box = styled.div`
  filter: drop-shadow(0px 0px 11px rgba(0, 49, 153, 0.6));
  background: white;
  padding: 1rem;
  width: 100%;
  margin: 0.5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${media.sm} {
    margin: 1rem;
    width: calc(50% - 2rem);
  }

  @media ${media.md} {
    padding: 2rem;
  }

  @media ${media.lg} {
    width: calc(50% - 2rem);
    max-width: 590px;
  }

  h3 {
    font-family: ${(props) => props.theme.font.family.special};
    color: ${(props) => props.theme.colors.navy};
    font-size: 24px;
    margin-bottom: 1rem;
    text-align: center;

    @media ${media.sm} {
      font-size: 30px;
      line-height: 35px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    button {
      max-width: 250px;
      margin: 0 auto;
      padding: 0.45rem 0;
      width: 100%;
      display: block;
      background: linear-gradient(135deg, #7bf6ef 0%, #3400a3 100%);
      border: none;
      position: relative;
      cursor: pointer;

      span {
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        font-size: 1.1rem;
        position: relative;
        z-index: 5;
      }

      &:hover::before {
        opacity: 1;
      }

      &::before {
        position: absolute;
        content: "";
        inset: 0;
        z-index: 1;
        opacity: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s linear;
        background: linear-gradient(
          135deg,
          #9a8aff 0%,
          #3b1293 48.96%,
          #000355 100%
        );
      }
    }
  }
`;

const MePlusThree = () => {
    const [open, setOpen] = useState(false);
    const [group, setGroup] = useState("");
    const toggle = (group) => {
        setGroup(group);
        setOpen(true);
    };
    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else if (!open) {
            document.body.style.overflow = "auto";
        }
    }, [open]);
    return (
        <EarlyBookerStyles>
            <div className="title">
                <h2>4 or More!</h2>
                <p>4 or more offer: purchase four tickets ranging in price from &pound;59.50 to &pound;79.50 for a total cost of &pound;199 on select performances through&nbsp;14 July 2024.&nbsp;Purchase additional tickets ranging in price from &pound;59.50 to &pound;79.50 for a total cost of &pound;49.50 per additional ticket on selected performances through&nbsp;14 July 2024. Maximum of 8 tickets per purchase.&nbsp;Book by 27 May 2024 to redeem this offer. Excludes all performances between 25th May to 1st June 2024.</p>
                <p>Please see terms and conditions below for full details and exclusion periods.</p>
            </div>
            <BoxWrapper>
                <Box>
                    <h3>BUY NOW</h3>
                    <p>Book 4 or more tickets and save up to 44% on Stalls or Royal Circle tickets.</p>
<p>Get&nbsp;<strong>4 tickets for &pound;199, additional tickets are &pound;49.75 each</strong>&nbsp;(usually &pound;59.50 - &pound;79.50). Maximum of 8 tickets per purchase.</p>
<p>Need more than 8 tickets? Learn about our 9+ Group Rates&nbsp;<a href="https://frozenthemusical.co.uk/groups/" target="_blank" rel="noopener noreferrer">here</a>.</p>
                    <a
                        href="https://ticketing.lwtheatres.co.uk/event/234/?PROMO=FOURORMORE"
                        className="btn btn--new btn--wide d-flex mx-auto text-uppercase"
                    >
                        <span>BOOK 4 OR MORE</span>
                    </a>
                </Box>
            </BoxWrapper>

            <Container fluid={true} className="no-padding-xs mt-4">
                <Container>
                    <Row>
                        <Col xl={12} className="my-auto pt-4 pt-xl-0">
                            <p className="mt-5 mb-1 enhance color--black text-center" id="#tandc">
                                <strong>Terms and conditions</strong>
                            </p>

                            <section className="terms">
                            <p>4 or more offer: purchase four tickets ranging in price from &pound;59.50 to &pound;79.50 for a total cost of &pound;199 on select performances through&nbsp;14 July 2024. Purchase additional tickets ranging in price from &pound;59.50 to &pound;79.50 for a total cost of &pound;49.50 per additional ticket on selected performances through 14 July 2024. Maximum of 8 tickets per purchase.&nbsp;</p>
                            <ul>
                            <li><strong>Valid performances</strong>. The following performances&nbsp;<strong><em>excluding</em></strong> all school holiday weeks: Wednesday to Friday evenings,&nbsp;Thursday matinees and Sunday 5.30pm performances through 14 July 2024.</li>
                            <li>4 tickets&nbsp;minimum&nbsp;must be purchased in order to complete each transaction.</li>
                            <li>8 tickets maximum per purchase.</li>
                            <li>Tickets must all be for the same performance; the offer cannot be split across multiple performances.</li>
                            <li>Subject to availability. Only available on eligible performances and price bands.</li>
                            <li>Offer pricing cannot be claimed retrospectively and cannot be combined with any other offer or promotion.</li>
                            <li>All sales final. No refunds or exchanges are permitted.</li>
                            <li>Dates, times and cast are subject to change without notice.</li>
                            <li>FROZEN is recommended for ages 6+. Children under the age of 4 (including babes in arms) will not be admitted to the theatre.</li>
                            <li>All persons aged 16 or under must be accompanied by an adult and may not sit on their own in the auditorium.</li>
                            <li>Accessible seating is available via email on&nbsp;<a href="mailto:access@lwtheatres.co.uk" target="_blank" rel="noopener noreferrer">access@lwtheatres.co.uk</a>&nbsp;or telephone 020 3925 2998.</li>
                            <li>Booking must be made by 27 May 2024.</li>
                            </ul>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </EarlyBookerStyles>
    );
};
export default MePlusThree;